import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore'
import InfoIcon from '@mui/icons-material/Info'
import LayersIcon from '@mui/icons-material/Layers'
import { Link } from 'react-router-dom'
import i18n from '@locales/i18n'
export type DrawerProps = {
  state: boolean
  toggleDrawer: (
    state: boolean,
    event: React.KeyboardEvent | React.MouseEvent | null
  ) => void
}

const MainDrawer: React.FC<DrawerProps> = ({ state, toggleDrawer }) => {
  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={(e) => {
        toggleDrawer(false, e)
      }}
      onKeyDown={(e) => {
        toggleDrawer(false, e)
      }}
    >
      <List>
        <a
          key={'mainSite'}
          target={'_blank'}
          href={'https://healthfirst.mn/'}
          rel="noreferrer"
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <LayersIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t('mainSite')} />
            </ListItemButton>
          </ListItem>
        </a>

        <Link
          key={'marketPlace'}
          to={{
            pathname: '/',
          }}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <LocalGroceryStoreIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t('marketPlace')} />
            </ListItemButton>
          </ListItem>
        </Link>

        <Link
          key={'howtoplay'}
          to={{
            pathname: '/howtoplay',
          }}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t('howToPlay')} />
            </ListItemButton>
          </ListItem>
        </Link>
      </List>
      <Divider />
    </Box>
  )

  return (
    <div>
      {(['left'] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state}
            onClose={() => {
              toggleDrawer(false, null)
            }}
          >
            {list()}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  )
}
export default MainDrawer
