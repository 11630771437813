import React from 'react'
import MainLayout from 'layouts/MainLayout'
import { CustomRouter } from 'customRouter'
import customHistory from './customHistory'
import { Route, Routes } from 'react-router-dom'
import Home from '@pages/home'
import HowToPlay from '@pages/howtoplay'
function App() {
  return (
    <CustomRouter history={customHistory}>
      <Routes>
        <Route path="/">
          <Route
            index
            element={
              <MainLayout>
                <Home />
              </MainLayout>
            }
          />
        </Route>
        <Route path="/howtoplay">
          <Route
            index
            element={
              <MainLayout>
                <HowToPlay />
              </MainLayout>
            }
          />
        </Route>
      </Routes>
    </CustomRouter>
  )
}

export default App
