import React, { ReactNode, useState } from 'react'
import { Box } from '@mui/material'
import Drawer from '@components/Drawer'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import '@theme/globals.css'
import i18n from '@locales/i18n'
export type MainLayoutProps = {
  children: ReactNode
}
const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const [drawerState, setDrawerState] = useState<boolean>(false)
  const toggleDrawer = (
    open: boolean,
    event: React.KeyboardEvent | React.MouseEvent | null
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }
    setDrawerState(open)
  }

  function appBarLabel(label: string) {
    return (
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={(e) => {
            toggleDrawer(!drawerState, e)
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          {label}
        </Typography>
      </Toolbar>
    )
  }

  return (
    <Box>
      <AppBar position="static">{appBarLabel(i18n.t('marketPlace'))}</AppBar>
      <Drawer toggleDrawer={toggleDrawer} state={drawerState} />
      {children}
    </Box>
  )
}

export default MainLayout
