import React from 'react'
import styles from '@theme/page.module.css'
import i18n from '@locales/i18n'
const Home = () => {
  return (
    <main className={styles.main}>
      <div className={styles.description}></div>

      <div className={styles.center}>
        <h2 style={{ lineHeight: '1.5', fontSize: '30px' }}>
          {i18n.t('commingSoon')}
        </h2>
      </div>
      <div className={styles.grid}></div>
    </main>
  )
}

export default Home
