import { createTheme } from '@mui/material/styles'
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ffffff',
    },
  },
})

export default theme
